<template>
  <v-dialog
    v-model="alertIsActive"
    max-width="290"
    @click:outside="closeAlert"
    transition="dialog-bottom-transition"
  >
    <v-alert
      border="bottom"
      :color="type"
      dark
    >
      {{ testo }}
    </v-alert>
  </v-dialog>
</template>

<script>
  export default {
    name: 'Alert',
    data() {
      return {
        alertIsActive: false,
        testo: '',
        type: ''
      }
    },
    created() {      
      this.EventBus.$on('showAlert', ([testo, type]) => {
          this.testo = testo;
          if(!type) type = success;
          this.type = type;
          this.alertIsActive = true;
      });
    },
    methods: {
      closeAlert(){
        this.alertIsActive = false;
      }
    },
  }
</script>
